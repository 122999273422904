.poster {
  opacity: 0.8;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
}


.poster:hover {
  opacity: 1;
}

.category {
  font-size: 30px;
  color: #ccc;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.main {
  margin-top: 50px;
}

@media (max-width: 960px) {
  .category {
    font-size: 20px;
  }
}
